$(document).ready(function (e) {
	btnBurger();
	btnSearch();
	dropdown();
	tabActive();
	commentHover();
	lightgallery();
	copyLink();
	swiper();
});

function lightgallery() {
	if ($('.lightgallery-wrap').length) {
		$(".lightgallery").lightGallery({
			thumbnail:true,
			animateThumb: false,
			showThumbByDefault: false,
		});
	}
}
function swiper () {
	if ($('.swiper-wrap').length) {
		var swiper = new Swiper('.swiper-container', {
			navigation: {
				nextEl: '.swiper-next',
				prevEl: '.swiper-prev',
			}
		});
	}
}

function btnBurger(e) {
	$('.burger').on('click', function() {
		const $this = $(this);
		const $parent = $this.parents('.header-content');

		if ($parent.hasClass('burger-open')) {
			$parent.removeClass('burger-open')
		} else {
			$parent.addClass('burger-open');
			$parent.removeClass('search-open');
		}
	})
	$(".wrapper").on("click", function(e) {
		if (!$(e.target).closest(".burger, .nav-list").length) {
			if ($(".header-content").hasClass("burger-open")) {
				$(".header-content").removeClass("burger-open");
			}
		}
	});
}
function btnSearch(e) {
	$('.search-btn').on('click', function() {
		const $this = $(this);
		const $parent = $this.parents('.header-content');

		if ($parent.hasClass('search-open')) {
			$parent.removeClass('search-open')
		} else {
			$parent.addClass('search-open');
			$parent.removeClass('burger-open');
		}
	}); 

	$(".wrapper").on("click", function(e) {
		if (!$(e.target).closest(".search-btn, .form-search").length) {
			if ($(".header-content").hasClass("search-open")) {
				$(".header-content").removeClass("search-open");
			}
		}
	});
}

function dropdown(e) {
	$(".btn-dropdown").on("click", function() {
		let $this = $(this);
		let $parent = $this.parents('.sort-wrap');

		if ($parent.hasClass("active")) {
			$parent.removeClass("active");
		} else {
			$parent.removeClass("active");
			$parent.addClass("active");
		}
	});

	$(".wrapper").on("click", function(e) {
		if (!$(e.target).closest(".btn-dropdown, .dropdown").length) {
			$(".sort-wrap").removeClass("active");
		}
	});
}

function tabActive(e) { 
	$('.tab-trigger').click(function(e) {
		let id = $(this).attr('data-tab');
		let content = $('.tab-box[data-tab="' + id + '"]');

		$('.tab-trigger.active').removeClass('active');
		$(this).addClass('active');

		$('.tab-box.active').removeClass('active');
		content.addClass('active')
	})
}

function commentHover(e) {
	$('.comment a, .comment .box-image').hover(function(e) {
		$(this).closest('.comment').toggleClass('active')
	})
}

function copyLink() {
	$(".share-box input").on("click", function() {
		$(this).select();
	});
}